import React from "react";
import Slider from "react-slick";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, boxShadow: "inset 0 0 0 2px #ffffff !important" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, boxShadow: "inset 0 0 0 2px #ffffff !important" }}
      onClick={onClick}
    />
  );
}

export default class SimpleSlider extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    var settings = {
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 4000,
      cssEase: "linear",
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
    return (
      <Slider {...settings}>
        {
          this.props.images.map((evt, index) => {
            return (
              <span className="image fit" key={index}>
                <img className="lazyload" data-src={evt} alt="Team members" />
              </span>
            )
          })
        }
      </Slider>
    );
  }
}