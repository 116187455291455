import React from 'react'
import Layout from '../components/layout'
import front1 from '../assets/images/team/front1.jpg'
import front2 from '../assets/images/team/front2.jpg'
import front3 from '../assets/images/team/front3.jpg'
import marcela from '../assets/images/team/marcela.jpg'
import felipe from '../assets/images/team/felipe.jpg'
import fayber from '../assets/images/team/fayber.jpg'
import hermes from '../assets/images/team/hermes.jpg'
import SimpleSlider from '../components/SimpleSlider'


class About extends React.Component {
  render() {
    return (
      <Layout>
        <div id="main" className="alt">
          <section id="one">
            <div className="inner">
              <header className="major">
                <h1>Sobre nosotros</h1>
              </header>
              <SimpleSlider images={[front1, front2, front3]}/>
              <div className="grid-wrapper">
                <div className="col-8">
                  <h3>Nuestro equipo</h3>
                  <p>
                    El capital humano de cualquier empresa es fundamental para su funcionamiento, en Trámites Dorado creemos que tenemos un equipo conformado por personas con mucha sensibilidad y humanidad, dispuestas a colaborar 
                    en la realización de cualquier trámite legal ante las secretarías del tránsito.
                  </p>
                  <div className="grid-wrapper">
                    <div className="box alt col-4">
                      <span className="image fit">
                        <img className="lazyload" data-src={hermes} alt="Hermes Dorado Dorado" />
                      </span>
                      <h4>Hermes Dorado</h4>
                      <p>Gerente general</p>
                    </div>
                    <div className="box alt col-4">
                      <span className="image fit">
                        <img className="lazyload" data-src={marcela} alt="Marcela Dorado" />
                      </span>
                      <h4>Marcela Dorado</h4>
                      <p>Secretaria general</p>
                    </div>
                    <div className="box alt col-4">
                      <span className="image fit">
                        <img className="lazyload" data-src={fayber} alt="Fayber Gueche" />
                      </span>
                      <h4>Fayber Gueche</h4>
                      <p>Asesor de trámites</p>
                    </div>
                    <div className="box alt col-4">
                      <span className="image fit">
                        <img className="lazyload" data-src={felipe} alt="Felipe Dorado" />
                      </span>
                      <h4>Felipe Dorado</h4>
                      <p>Asesor de trámites</p>
                    </div>
                  </div>
                  <h3>Nuestra historia</h3>
                  <p>
                    "Hace 17 años, después de haber trabajado como taxista y
                    tener varios problemas con mi vehículo, me encuentro con la
                    necesidad de vender mi taxi y cambiar de labor. Sin buscarlo
                    encontré mi ocupación al decidir realizar el trámite para la
                    venta de mi vehículo por mi cuenta y además realizar los
                    trámites de los demás. En el 2001 surgió la idea de montar
                    una oficina donde la gente pudiera ahorrar tiempo delegando
                    sus trámites ante las secretarías de tránsito, entonces
                    nació Trámites Dorado, oficina que actualmente es una
                    microempresa familiar dedicada a la realización de varios
                    tipos de trámites y otros servicios relacionados."
                  </p>
                </div>
                <div className="col-4">
                  <div className="box">
                    <h3>Misión</h3>
                    <p>
                      Tramites Dorado es una microempresa familiar Timbiana,
                      dedicada a la prestación de servicios y/o asesorías ante
                      las diferentes secretarias de tránsito a nivel nacional,
                      bajo las normas legales vigentes y el código nacional de
                      tránsito; brindamos a nuestros clientes agilidad,
                      seguridad y confianza. Realizamos todo tipo de trámites
                      para vehículos, licencias de conducción, expedición de
                      SOAT, adicionalmente brindamos el servicio de fotocopias,
                      laminados, impresiones y muchos otros más.
                    </p>
                  </div>
                  <div className="box">
                    <h3>Visión</h3>
                    <p>
                      Para el 2025, Tramites Dorado será una empresa líder en la
                      prestación de servicios y/o asesorías ante las secretarias
                      de tránsito a nivel nacional, con diferentes sucursales en
                      el territorio Colombiano para una mejor prestación del
                      servicio, distinguiéndose por su confiabilidad,
                      responsabilidad, eficiencia y eficacia para ser un
                      referente nacional.
                    </p>
                  </div>
                  <div className="box">
                    <h3>Valores institucionales</h3>
                    <ul>
                      <li>Responsabilidad</li>
                      <li>Honestidad</li>
                      <li>Cumplimiento</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export default About
